import React from "react";
import AboutUs from "../../components/AboutUs/AboutUs";
import Cover from "../../components/Cover/Cover";
import Footer from "../../components/Footer/Footer";
import Gift from "../../components/Gift/Gift";
import InfoAndSuggestions from "../../components/InfoAndSuggestions/InfoAndSuggestions";
import OurWedding from "../../components/OurWedding/OurWedding";
import Rsvp from "../../components/Rsvp/Rsvp";
import Divider from "../../components/Divider/Divider";
import "./HomePage.css";

function HomePage() {
  return (
    <div className="home-page">
      <Cover />
      <AboutUs />
      <OurWedding />
      <InfoAndSuggestions />
      <Gift />
      <Rsvp />
      <Divider />
      <Footer />
    </div>
  );
}

export default HomePage;
