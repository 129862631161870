import React from "react";
import Section from "../../common/Section/Section";
import "./Footer.css";

function Footer() {
  return (
    <Section className="footer">
      <p>
        <small>Gabri & Ale 2023 © Tutti i diritti riservati</small>
      </p>
      <small>
        Il nostro orgoglio da programmatori ci ha imposto di fare questo sito a
        mano. Così.. giusto per lavorare fino a tardi per qualche settimana.
        Speriamo ti sia piaciuto e che tu non abbia trovato bug. Se li hai
        trovati, succede; se non li hai trovati, li abbiamo nascosti bene. Ecco
        la lista della spesa di quello che abbiamo usato per metterlo in piedi:
        React per il frontend, Firebase per il backend, Firestore come database,
        Github per il versionamento, componente &quot;Add To Calendar
        Button&quot; che si spiega da sé, Ionos per il dominio, Depositphotos
        per le immagini, Figma per lavorare sugli SVG, Chat GPT per lo
        scaffolding iniziale e, infine, un&apos;abbondante dose di fantasia. 🦄
      </small>
    </Section>
  );
}

export default Footer;
