import React from "react";
import "./Section.css";

function Section({ children, className, id }) {
  return (
    <div className={`section ${className}`} id={id && `${id}`}>
      {children}
    </div>
  );
}

export default Section;
