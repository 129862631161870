import React from "react";
import Section from "../../common/Section/Section";
import "./Cover.css";

function Cover() {
  return (
    <Section className="cover">
      <svg className="light-rays">
        <rect
          width="500vw"
          height="60px"
          style={{
            fill: "rgb(255, 255, 255, 10%)",
          }}
        />
        <rect
          y="60px"
          width="500vw"
          height="25px"
          style={{
            fill: "rgb(255, 255, 255, 30%)",
          }}
        />
        <rect
          y="85px"
          width="500vw"
          height="30px"
          style={{
            fill: "rgb(255, 255, 255, 10%)",
          }}
        />
        <rect
          y="115px"
          width="500vw"
          height="35px"
          style={{
            fill: "rgb(255, 255, 255, 25%)",
          }}
        />
        <rect
          y="150px"
          width="500vw"
          height="70px"
          style={{
            fill: "rgb(255, 255, 255, 15%)",
          }}
        />
        <rect
          y="220px"
          width="500vw"
          height="20px"
          style={{
            fill: "rgb(255, 255, 255, 20%)",
          }}
        />
        <rect
          y="240px"
          width="500vw"
          height="40px"
          style={{
            fill: "rgb(255, 255, 255, 10%)",
          }}
        />
        <rect
          y="280px"
          width="500vw"
          height="60px"
          style={{
            fill: "rgb(255, 255, 255, 5%)",
          }}
        />
      </svg>
      <img
        className="logo"
        alt="Logo matrimonio Ale e Gabri"
        src="assets/images/logo.svg"
      ></img>
      <h1 className="title">Wedding</h1>
      <h4 className="subtitle">-SUMMER PARTY-</h4>
      <div className="row mt-30">
        <a className="btn btn-main btn-cta" href="#about-us">
          Scopri di più
        </a>
      </div>
    </Section>
  );
}

export default Cover;
