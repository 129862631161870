import React from "react";
import Section from "../../common/Section/Section";
import "./InfoAndSuggestions.css";

function InfoAndSuggestions() {
  return (
    <Section className="info-and-suggestions">
      <div>
        <h3>Info & Suggerimenti</h3>
        <div className="list">
          <p id="bus">
            <em>Organizzeremo due corriere</em>, una che parte da Fontanafredda
            (PN) e una da Trieste. La festa finirà verso mezzanotte circa, ma
            organizzeremo l&apos;orario di ritorno in base alle persone che ci
            saranno. Se sei interessato/a a usufruire della corrriera, faccelo
            sapere compilando il modulo di partecipazione in fondo a questa
            pagina!
          </p>
          <p id="children">
            <em>
              Durante la cena i bambini fino ai 12 anni staranno in una sala
              adiacente a quella degli adulti
            </em>
            , in compagnia di uno o più animatori, che provvederanno a dare loro
            anche la cena (un po&apos; come in asilo). In caso contrario
            dovranno stare in braccio ai genitori. Abbiamo deciso di dare
            priorità agli adulti durante la cena all&apos;interno del castello
            per esigenze di spazio. <u>Nota importante</u>: il giardino del
            castello è totalmente aperto da un lato e ha anche una piscina. Gli
            animatori saranno presenti solo per la durata della cena.
          </p>
          <p>
            <em>Porta un cappello!</em>&nbsp;Ci sarà molto sole (speriamo), non
            vogliamo vederti svenire!
          </p>
          <p>
            <em>Indossa scarpe comode!</em>&nbsp;Vogliamo che i piedi ti
            facciano male, sì... ma solo per aver ballato troppo! E poi saremo
            su un prato, meglio evitare i tacchi.
          </p>
          <p>
            <em>Ti suggeriamo di fare un pranzo leggero</em>&nbsp;prima di
            venire alla cerimonia, per resistere qualche ora. Ci sarà molto da
            mangiare poi!
          </p>
          <p>
            <em>Ci sarà una piscina</em>. Nel dubbio, porta un costume, ché non
            si sa mai!
          </p>
        </div>
      </div>
    </Section>
  );
}

export default InfoAndSuggestions;
