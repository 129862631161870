import React from "react";
import Section from "../../common/Section/Section";
import "./Gift.css";

function Gift() {
  return (
    <Section className="gift">
      <h3>Vuoi Farci un regalo?</h3>
      <p>
        La tua presenza è il regalo più bello che puoi farci, ma se vuoi anche
        mandarci a quel paese contatta l&apos;
        <em>agenzia di viaggi &quot;Smile And Go&quot; di Trieste</em>:
      </p>
      <p>
        <a href="tel:040-3499050">040-3499050</a>
      </p>
      <p>
        <a href="mailto:info@smileandgoviaggi.it?subject=Regalo matrimonio Alessandra Laderchi e Gabriele Savio">
          info@smileandgoviaggi.it
        </a>
      </p>

      {/* <p className="bank">
        <b>Beneficiario</b>: Agenzia Viaggi Smile and Go di Viaggi da Sogno SNC{" "}
        <br />
        <b>Banca</b>: Banca di Cividale <br />
        <b>Causale</b>: Lista nozze Fam. Savio da parte di ... <br />
        <b>IBAN</b>: IT14F0548402201CC0740457242 <br />
      </p> */}
      <p>
        Oppure, se preferisci, puoi lasciarci una cara e sempre apprezzata
        &quot;busta con dedica&quot;. :-D
      </p>
      <p>
        Te ne saremo molto grati, ci aiuterai a realizzare il nostro sogno di
        viaggiare in Namibia e alle Seychelles!
      </p>
      <img
        className="honeymoon"
        alt="Foto di Namibia, Seychelles e mondo con itineriario"
        src="assets/images/honeymoon.jpg"
      />
    </Section>
  );
}

export default Gift;
