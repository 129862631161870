import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  addDoc,
  collection,
  query,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import Section from "../../common/Section/Section";
import AutocompleteInput from "./AutoCompleteInput";
import "./Rsvp.css";

const firebaseConfig = {
  apiKey: "AIzaSyCJzwQXSPHIy3KmH0_sVrovx_Z5_0c8uSM",
  authDomain: "gabrieale-wedding.firebaseapp.com",
  projectId: "gabrieale-wedding",
  storageBucket: "gabrieale-wedding.appspot.com",
  messagingSenderId: "904211246695",
  appId: "1:904211246695:web:627f0c97d60e77718eeec2",
  measurementId: "G-2VBRNRN82Q",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function Rsvp() {
  const [searchValue, setSearchValue] = useState("");
  const [inputs, setInputs] = useState({
    fullname: "",
    rsvp: "",
    bus: "",
    age: "",
    email: "",
    dietaryRestrictions: "",
  });
  const [invitedGuests, setInvitedGuests] = useState([]);

  function setFullname(text) {
    setInputs({
      ...inputs,
      fullname: text,
    });
  }

  async function sendToFirestore() {
    return await addDoc(collection(db, "guests"), {
      ...inputs,
      timestamp: serverTimestamp(),
    });
  }

  function handleSubmit(event) {
    event.preventDefault();

    sendToFirestore()
      .then(() => {
        console.log("Document successfully written!");
        alert("✔ Modulo inviato, grazie!");
        handleClear();
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        alert(
          "❌ Si è verificato un errore, riprova. Se l'errore persiste, contattaci!"
        );
      });
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  function handleClear() {
    setInputs({
      fullname: "",
      rsvp: "",
      bus: "",
      age: "",
      email: "",
      dietaryRestrictions: "",
    });
    setSearchValue("");
  }

  async function initInvitedGuests() {
    const q = query(collection(db, "invitedGuests"));
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    setInvitedGuests(data);
  }

  useEffect(() => {
    initInvitedGuests();
  }, []);

  return (
    <Section className="rsvp">
      <h3>Conferma la tua presenza</h3>
      <p>
        Ti chiediamo gentilmente di compilare questo modulo entro il{" "}
        <strong>31 maggio 2023</strong>, per permetterci di organizzare al
        meglio la giornata!
      </p>
      <p>I campi contrassegnati con asterisco (*) sono obbligatori.</p>
      <p>
        <u>Compila e invia un modulo per ogni invitato della tua famiglia</u>,
        adulto, bambino o neonato che sia.
      </p>
      <p>
        Per qualsiasi problema o dubbio, contattaci:
        <br />
        Alessandra 3420656229
        <br />
        Gabriele 3924271919
      </p>

      <form onSubmit={handleSubmit}>
        <fieldset>
          <legend>Come ti chiami? *</legend>
          <AutocompleteInput
            id="name"
            name="fullname"
            inputSuggestions={invitedGuests}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            fullname={inputs.fullname}
            setFullname={setFullname}
            required
            disabled
          />
          <p>
            <small>
              Scrivi prima il tuo nome e poi il tuo cognome. Vedrai comparire il
              tuo nome completo, selezionalo per proseguire nella compilazione
              del modulo. Se non trovi il tuo nome, contattaci.{" "}
            </small>
          </p>
        </fieldset>

        <div className="form-body">
          <fieldset>
            <legend>Parteciperai? *</legend>
            <select
              name="rsvp"
              value={inputs.rsvp}
              onChange={handleChange}
              disabled={!inputs.fullname}
              required
            >
              <option value="" disabled>
                Seleziona...
              </option>
              <option value="yes">Sì</option>
              <option value="no">Non posso</option>
            </select>
          </fieldset>
          <fieldset>
            <legend>Qual è la tua fascia d&apos;età? *</legend>
            <select
              name="age"
              value={inputs.age}
              onChange={handleChange}
              disabled={!inputs.fullname}
              required
            >
              <option value="" disabled>
                Seleziona...
              </option>
              <option value="adult">Adulto</option>
              <option value="young">3-12 anni</option>
              <option value="baby">0-2 anni</option>
            </select>
            <p>
              <small>
                Leggi la <a href="#children">nota riguardo ai bambini</a>.
              </small>
            </p>
          </fieldset>
          <fieldset>
            <legend>Vuoi usare la corriera? *</legend>
            <select
              name="bus"
              value={inputs.bus}
              onChange={handleChange}
              disabled={!inputs.fullname}
              required
            >
              <option value="" disabled>
                Seleziona...
              </option>
              <option value="no">No, grazie</option>
              <option value="yes_pn">Sì, da Fontanafredda (PN)</option>
              <option value="yes_ts">Sì, da Trieste</option>
            </select>
            <p>
              <small>
                Leggi la <a href="#bus">nota riguardo alla corriera</a>.
              </small>
            </p>
          </fieldset>
          <fieldset>
            <legend>Qual è il tuo indirizzo email?</legend>
            <input
              name="email"
              type="email"
              value={inputs.email}
              placeholder="Scrivi..."
              onChange={handleChange}
              disabled={!inputs.fullname}
            />
            <p>
              <small>
                Lo useremo solo per avvisarti in caso di aggiornamenti
                importanti e per mandarti un promemoria con informazioni utili
                qualche giorno prima della festa.{" "}
              </small>
            </p>
          </fieldset>
          <fieldset>
            <legend>Hai esigenze specifiche riguardo al cibo?</legend>
            <textarea
              name="dietaryRestrictions"
              value={inputs.dietaryRestrictions}
              onChange={handleChange}
              placeholder="Scrivi..."
              disabled={!inputs.fullname}
            />
            <p>
              <small>
                Se hai qualche intolleranza specifica o segui una dieta
                particolare, scrivicelo qui. Cercheremo di soddisfare i bisogni
                di ognuno, nei limiti del possibile.
              </small>
            </p>
          </fieldset>

          <div className="buttons">
            <button
              type="submit"
              className="btn-main"
              disabled={!inputs.fullname}
            >
              Invia
            </button>
            <button
              type="reset"
              className="btn-secondary"
              onClick={handleClear}
            >
              Ripulisci
            </button>
          </div>
        </div>
      </form>
    </Section>
  );
}

export default Rsvp;
