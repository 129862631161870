import React, { useState } from "react";

function AutocompleteInput({
  inputSuggestions,
  searchValue,
  setSearchValue,
  fullname,
  setFullname,
  disabled,
}) {
  const [suggestions, setSuggestions] = useState([]);

  const handleSuggestionClick = (suggestion) => {
    setSearchValue(suggestion);
    setSuggestions([]);
    setFullname(suggestion);
  };

  const handleInputChange = async (e) => {
    const searchValue = e.target.value;
    setSearchValue(searchValue);

    if (searchValue === "" || searchValue.length < 3) {
      setSuggestions([]);
    } else {
      const filteredSuggestions = inputSuggestions.filter((s) =>
        s.fullname.toLowerCase().startsWith(searchValue.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    }
  };

  return (
    <div className="dropdown">
      <input
        type="text"
        value={searchValue}
        placeholder="Cerca..."
        onChange={handleInputChange}
        disabled={disabled || !!fullname}
      />

      {suggestions.length > 0 && (
        <ul className="dropdown-content">
          {suggestions.map((suggestion) => (
            <button
              type="button"
              className="dropdown-btn"
              key={suggestion.id}
              onClick={() => handleSuggestionClick(suggestion.fullname)}
            >
              {suggestion.fullname}
            </button>
          ))}
        </ul>
      )}
    </div>
  );
}

export default AutocompleteInput;
