import React from "react";
import Section from "../../common/Section/Section";
import "./OurWedding.css";
import { AddToCalendarButton } from "add-to-calendar-button-react";

function OurWedding() {
  return (
    <Section className="our-wedding">
      <p>L&apos;appuntamento è questo:</p>
      <h4>Venerdì 21 Luglio 2023</h4>
      <h4>Castello Formentini (GO)</h4>
      <h4>Ore 14:00</h4>
      <div className="row mt-30">
        <AddToCalendarButton
          label="Aggiungi al calendario"
          name="Matrimonio Alessandra & Gabriele"
          startDate="2023-07-21"
          startTime="14:00"
          endTime="23:59"
          timeZone="Europe/Rome"
          location="Castello Formentini, Piazza Libertà, 3, 34070 San Floriano del Collio GO"
          customCss="assets/css/atcb-button.css"
          buttonStyle="custom"
          trigger="click"
          options={[
            "Apple",
            "Google",
            "iCal",
            "Microsoft365",
            "Outlook.com",
            "Yahoo",
          ]}
        ></AddToCalendarButton>
      </div>
    </Section>
  );
}

export default OurWedding;
