import React from "react";
import "./Divider.css";

function Divider() {
  return (
    <div className="divider">
      <img
        src="assets/images/party.svg"
        alt="Immagine di persone che ballano"
      />
    </div>
  );
}

export default Divider;
