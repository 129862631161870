import React, { useEffect, useState } from "react";
import Section from "../../common/Section/Section";
import "./AboutUs.css";

function AboutUs() {
  const pictures = [
    {
      src: "assets/images/img-1.jpg",
      alt: "Ale e Gabri proposta di matrimonio",
      back: () => (
        <>
          Quella volta che ha detto &quot;Sì!&quot; e si è messa l&apos;anello.
          <br />
          <small>Verona, 16 Marzo 2022</small>
        </>
      ),
    },
    {
      src: "assets/images/img-2.jpg",
      alt: "Ale e Gabri in Friuli",
      back: () => (
        <>
          Uno dei tanti giri all&apos;estero.
          <br />
          <small>Arta Terme (UD), Ottobre 2022</small>
        </>
      ),
    },
    {
      src: "assets/images/img-3.jpg",
      alt: "Ale e Gabri scemi",
      back: () => (
        <>
          La serietà.
          <br />
          <small>Trieste, Capodanno 2022</small>
        </>
      ),
    },
  ];

  const [mobilePicture, setMobilePicture] = useState();

  useEffect(() => {
    if (!mobilePicture) {
      const idx = Math.floor(Math.random() * (3 - 0));
      setMobilePicture(pictures[idx]);
    }
  }, []);
  return (
    <Section className="about-us" id="about-us">
      <h2>Ci sposiamo!</h2>
      <p>Ebbene sì, il momento è arrivato!</p>
      <p>
        Se stai leggendo questo sito è perché vorremmo che festeggiassi con noi
        questo importante traguardo!
      </p>
      <div className="desktop row gap mt-60">
        {pictures.map((item, idx) => (
          <div key={idx} className="card-container">
            <div className="card">
              <img className="front" src={item.src} alt={item.alt} />
              <div className="back">{item.back()}</div>
            </div>
          </div>
        ))}
      </div>
      {mobilePicture && (
        <div className="mobile row gap mt-60">
          <div className="card-container">
            <div className="card">
              <img
                className="front"
                src={mobilePicture.src}
                alt={mobilePicture.alt}
              />
              <div className="back">{mobilePicture.back()}</div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-60">
        Siamo due programmatori con la passione per i viaggi e il buon cibo,
        specialmente se lo cucina qualcun altro. Ci siamo conosciuti a un
        colloquio di lavoro nel lontano 2015, anni dopo ci siamo ritrovati a
        lavorare nella stessa azienda e ora eccoci qua. Nessun altro spoiler! Se
        verrai alla festa conoscerai tutti i dettagli!
      </div>
    </Section>
  );
}

export default AboutUs;
